import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Footer = ({ siteTitle, address, copyright, email, phoneNumber }) => (
  <div className="panel bg-accent  relative  z-50 relative px-0 py-6">
    <div className="flex xs:block w-90 mx-auto">
      <div className="w-65 flex xs:hidden sm:hidden">
        <p className="text-white mb-0 footer-text">
          <a href="mailto:info@betahills.com">{email}</a>
        </p>
      </div>

      <div class="w-35 sm:w-40 xs:w-100">
        <div class="flex justify-end md:ml-6">
          <div className="mt-3 md:w-40 xs:w-15 sm:w-20 xs:mx-0  text-right xs:text-left sm:text-left xs:mt-0 xs:pt-3">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/betahills"
            >
              <button className="btn border-1 border-solid border-white text-white h-rem-10 w-rem-10 p-0 rounded-full text-lg text-center">
                <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
              </button>
            </a>
          </div>

          <div className="xs:w-75 sm:w-80 md:w-70 text-left sm:text-left xs:text-left ml-10 xs:ml-8">
            <p className="text-white mb-0 footer-text lowercase">
              {copyright
                ? copyright
                : `©${new Date().getFullYear()} ${siteTitle}`}
            </p>
            <Link to="/imprint" className="text-white m-0 footer-text">
              Imprint / Privacy
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Footer.propTypes = {
  address: PropTypes.string,
  copyright: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
}

Footer.defaultProps = {
  address: "Musterstraße 12  12345 Berlin",
  copyright: null,
  email: "info@betahills.com",
  phoneNumber: "+49(0) 151 100 321",
  siteTitle: "betahills",
}

export default Footer
