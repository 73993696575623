import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import PropTypes from "prop-types"
import React from "react"
import Scrollspy from "react-scrollspy"

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { expanded: false, scrolling: false }
    this.expandText = this.expandText.bind(this)
  }

  expandText() {
    this.setState({ scrolling: true })
    // Update state so the next render will show the fallback UI.
    setTimeout(() => {
      this.setState({ scrolling: false })
    }, 700)
  }

  navItemsIds() {
    console.log(this.props.menuList.link)
    return this.props.menuList.map(item => {
      let url = item.url
      url = url.replace("/", "")
      url = url.replace("#", "")
      return url
    })
  }

  render() {
    return (
      <nav className="fixed z-9999 text-accent h-rem-20 w-100 hidden xl:block lg:block m-0 t-0 l-0 r-0 border-b-1 border-solid border-accent">
        <div className="w-90 relative flex mx-auto py-4 mx-0">
          <ul className="w-20 list my-auto mx-0 px-0 ">
            <li className="m-0 px-0 py-1">
              <Link to="/" className="font-primary">
                <img src={"/img/betahills_logo.svg"} alt="betahills-logo" />
              </Link>
            </li>
          </ul>

          <ul className="list w-80   text-right px-0 mr-0  text-xl pt-2">
            <Scrollspy
              items={this.navItemsIds()}
              offset={-200}
              currentClassName={
                !this.state.scrolling
                  ? "border-b-2 border-solid border-accent xs:border-0 sm:border-0 md:border-0 pb-2"
                  : ""
              }
            >
              {this.props.menuList.map(item => (
                <li
                  onClick={this.expandText}
                  className="inline-block mx-5 xs:border-b-1 xs:border-solid xs:border-accent my-0 xs:px-0 xs:block xs:inherit-color xs:no-underline xs:py-4 xs:font-primary md:block md:border-b-1 md:border-solid md:border-accent md:py-8 sm:border-b-1 sm:border-solid sm:border-accent sm:block sm:inherit-color sm:no-underline sm:py-4 sm:font-primary "
                >
                  {" "}
                  <AnchorLink
                    to={"/" + item.url}
                    className="mx-0 xs:pl-16 md:pl-16 sm:pl-16 nav-link-text xs:text-base  md:text-lg xs:text-accent md:text-accent sm:text-xs sm:text-accent w-100 h-100 block cursor-pointer"
                  >
                    {item.title}
                  </AnchorLink>
                </li>
              ))}
            </Scrollspy>
          </ul>
        </div>
      </nav>
    )
  }
}
Navbar.propTypes = {
  siteTitle: PropTypes.string,
  menuList: PropTypes.array,
}

Navbar.defaultProps = {
  siteTitle: `Betahills`,
  menuList: [
    {
      url: "/#about",
      title: "About",
    },
    {
      url: "/#process",
      title: "Process",
    },
    {
      url: "/#solutions",
      title: "Solutions",
    },
    {
      url: "/#case-studies",
      title: "Case studies",
    },
    {
      url: "/#impact",
      title: "Impact",
    },
    {
      url: "/#contact",
      title: "Contact",
    },
  ],
}

export default Navbar
