import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import NavbarItem from "./navItem"

class NavMobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = { expanded: false, animationEnded: true }
    this.expandText = this.expandText.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  expandText() {
    if (!this.state.expanded) {
      this.setState({ animationEnded: false })
    } else {
      setTimeout(() => {
        this.setState({ animationEnded: true })
      }, 700)
    }
    // Update state so the next render will show the fallback UI.
    this.setState({ expanded: !this.state.expanded })
  }

  closeMenu() {
    if (this.state.expanded) {
      this.expandText()
    }
  }

  render() {
    return (
      <nav className="fixed xl:hidden transition-500ms lg:hidden z-9999 t-0 mx-auto w-100">
        <div
          className={[
            "border-b-1 transition-500ms visible h-rem-14 md:h-rem-16 sm:h-rem-14 border-solid border-accent relative z-5",
            !this.state.animationEnded ? "bg-primary" : null,
          ].join(" ")}
        >
          <div className="w-90 py-3 mx-auto flex">
            <ul className="my-auto  md:w-80 sm:w-80 xs:w-65 list my-0 py-0">
              <li className="inline-block  my-0 py-0">
                <Link to="/" className="font-primary" onClick={this.closeMenu}>
                  <img
                    src={"/img/betahills_logo.svg"}
                    className="xs:w-80"
                    alt="betahills-logo"
                  />
                </Link>
              </li>
            </ul>

            <ul className="w-35 m-0 my-auto text-right">
              <button
                onClick={this.expandText}
                className="px-0 py-0 my-0 outline-none xs:text-base md:text-lg text-accent body-text p"
              >
                <p className="px-0 py-0 my-0">
                  {this.state.expanded ? "Close" : "Menu"}
                </p>
              </button>
            </ul>
          </div>
        </div>
        <div
          className={[
            "w-100 xl:hidden bg-primary lg:hidden h-screen overflow-scroll pb-16  relative z-0",
            this.state.expanded
              ? "animated fast fadeInDown"
              : "animated fast fadeOutUp",
            this.state.animationEnded ? "hidden" : null,
          ].join(" ")}
        >
          <ul className=" list">
            {this.props.menuList.map(item => (
              <NavbarItem
                key={item.url}
                link={item.url}
                text={item.title}
                onLinkSelected={this.expandText}
              />
            ))}
          </ul>
        </div>
      </nav>
    )
  }
}

NavMobile.propTypes = {
  siteTitle: PropTypes.string,
  menuList: PropTypes.array,
}

NavMobile.defaultProps = {
  siteTitle: `Betahills`,
  menuList: [
    {
      url: "/#about",
      title: "About",
    },
    {
      url: "/#process",
      title: "Process",
    },
    {
      url: "/#solutions",
      title: "Solutions",
    },
    {
      url: "/#case-studies",
      title: "Case studies",
    },
    {
      url: "/#impact",
      title: "Impact",
    },
    {
      url: "/#contact",
      title: "Contact",
    },
  ],
}

export default NavMobile
