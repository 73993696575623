/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "../utils/fontawesome"
import Navbar from "./layout/navbar"
import NavMobile from "./layout/navMobile"
import Footer from "./footer"
import "../styles/app.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      navbar: wordpressWpApiMenusMenusItems(slug: { eq: "main" }) {
        id
        slug
        name
        items {
          url
          type
          title
        }
      }
      footer: wordpressPost(slug: { eq: "footer" }) {
        id
        attributes: acf {
          copyright
          email
        }
      }
    }
  `)

  return (
    <div className="relative">
      <div className="relative">
        <Navbar menuList={data.navbar.items} />
        <NavMobile menuList={data.navbar.items} />
        <main>{children}</main>
        <Footer
          siteTitle={data.site.siteMetadata.title}
          address={data.footer.attributes.address}
          copyright={data.footer.attributes.copyright}
          email={data.footer.attributes.email}
          phoneNumber={data.footer.attributes.phone_number}
        />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
