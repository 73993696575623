import { AnchorLink as Link } from "gatsby-plugin-anchor-links"
import PropTypes from "prop-types"
import React from "react"

class NavbarItem extends React.Component {
  constructor(props) {
    super(props)
    this.linkClicked = this.linkClicked.bind(this)
  }

  linkClicked() {
    // Update state so the next render will show the fallback UI.
    this.props.onLinkSelected(true)
  }

  render() {
    return (
      <li
        onClick={this.linkClicked}
        className="inline-block px-5 xs:border-b-1 xs:border-solid xs:border-accent my-0 mr-0 xs:px-0 xs:block xs:inherit-color xs:no-underline xs:py-4 xs:font-primary md:block md:border-b-1 md:border-solid md:border-accent md:py-8 sm:border-b-1 sm:border-solid sm:border-accent sm:block sm:inherit-color sm:no-underline sm:py-4 sm:font-primary "
      >
        {" "}
        <Link
          to={"/" + this.props.link}
          className="mx-0 xs:pl-16 md:pl-16 sm:pl-16 nav-link-text xs:text-base  md:text-lg xs:text-accent md:text-accent sm:text-xs sm:text-accent w-100 h-100 block cursor-pointer"
        >
          {this.props.text}
        </Link>
      </li>
    )
  }
}
NavbarItem.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
}

NavbarItem.defaultProps = {
  link: `#`,
  text: `Item`,
}

export default NavbarItem
